@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Roman Bold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.trivia-container-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.trivia-container-question-inner {
    width: 80vw;
    padding: 1rem;
    background: white;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}

.trivia-container-points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.trivia-container-points-inner {
    width: 80vw;
    padding: 1rem;
    background: white;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    margin: 1rem;
    justify-content: center;
}

.trivia-points {
    display: flex;
}

.trivia-points-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(1rem + 1vw);
    padding-right: 1rem;
    border-right: 1px lightgray solid;
}

.trivia-points-text {
    text-align: center;
}

.trivia-question {
    font-weight: 500;
    font-size: calc(1.4rem + 1vw);
    max-width: 70%;
    text-align: center;
    padding-bottom: 2.5rem;
}
.trivia-answers {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}
.trivia-answer {
    font-weight: 500;
    padding: 1rem 0.5rem 1rem calc(0.7rem + 1vw);
    min-width: 69vw;
    font-size: calc(0.6rem + 1vw);
    text-align: start;
    font-weight: 500;
    border-radius: 2rem;
    background: #fff;
    border: 1px lightgray solid;
}

.trivia-answer:not(:last-child) {
    margin-bottom: 0.6rem;
}

.trivia-selected-charity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.6rem;
}

.trivia-donate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.6rem;
}

.glow-on-hover {
    width: 16vw;
    height: 3.5rem;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: calc(0.6rem + 0.9vw);
}

.glow-on-hover:before {
    content: "";
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing 20s linear infinite;
            animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@-webkit-keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.trivia-category {
    align-self: end;
    padding-left: 0.5rem;
}

.trivia-category-dropdown {
    width: calc(11rem + 5vw);
}

.trivia-top-bar {
    display: flex;
    justify-content: space-between;
    width: inherit;
    padding-bottom: 0.8rem;
}

.logout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-animation: gradient 15s ease infinite;
            animation: gradient 15s ease infinite;
}

* {
    font-family: "Rubik", sans-serif;
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.trivia-container-ads {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.trivia-container-ads-inner {
    width: 80vw;
    display: flex;
    align-items: center;
    margin: 1rem;
    justify-content: center;
}

.charities-leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.charities-leaderboard-inner {
    width: 80vw;
    padding: 1rem;
    background: white;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    background: linear-gradient(45deg, #23d5ab, #23a6d5, #e73c7e, #ee7752);
    background-size: 400% 400%;
    -webkit-animation: gradient 50s ease infinite;
            animation: gradient 50s ease infinite;
    color: #eef4f9;
}

.charities-leaderboard-header {
    font-size: calc(1rem + 1vw);
    font-weight: 500;
    margin-bottom: 1rem;
}

.charities-leaderboard-body {
    display: flex;
    flex-direction: column;
}

.charities-leaderboard-body-top3 {
    display: flex;
}

.charities-leaderboard-top3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.charities-leaderboard-top3:not(:last-child) {
    margin-right: 1.5rem;
}

.charities-leaderboard-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.charities-leaderboard-row:not(:last-child) {
    margin-bottom: 0.5rem;
}

.charities-leaderboard-row-name {
    font-size: calc(0.8rem + 0.1vw);
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: center;
}

.charities-leaderboard-row-points {
    font-size: calc(0.6rem + 0.1vw);
    font-weight: 300;
    margin-bottom: 0.5rem;
    background: #4c4eb3;
    padding: 0.4rem;
    border-radius: 1rem;
}

.charities-ranking-top3 {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    top: 0;
    left: 0;
    position: absolute;
    text-align: center;
    line-height: 1.5rem;
}

